import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  gql,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { createUploadLink } from "apollo-upload-client";
import { Modal } from "components";
import ENV from "env";
import { t } from "i18next";
import appStore from "stores/appStore";

let client;

const TOKEN = "AUTHENTICATION_TOKEN";
export const getToken = () => localStorage.getItem(TOKEN);
export const setToken = (token) => localStorage.setItem(TOKEN, token);
export const removeToken = () => localStorage.removeItem(TOKEN);

const REFRESH_TOKEN = "REFRESH_TOKEN";
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const setRefreshToken = (token) =>
  localStorage.setItem(REFRESH_TOKEN, token);
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN);

const isRefreshRequest = (operation) => {
  return operation.operationName === "GEN_REFRESH_TOKEN";
};

// Returns accesstoken if opoeration is not a refresh token request
const returnTokenDependingOnOperation = (operation) => {
  if (isRefreshRequest(operation)) {
    return getRefreshToken() || "";
  } else {
    return getToken() || "";
  }
};

const httpLink = createUploadLink({
  uri: ENV.API_ENDPOINT,
});

const authLink = setContext((operation, { headers }) => {
  const token = returnTokenDependingOnOperation(operation);
  return {
    headers: {
      ...headers,
      "x-tenant-id": "big",
      authorization: token ? `${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      switch (networkError?.statusCode) {
        case 401:
          // ignore 401 error for a refresh request
          if (operation.operationName === "GEN_REFRESH_TOKEN") {
            Modal.alert({
              className: "TokenExpire",
              title: t("client.api.modal401Title"),
              children: t("client.api.modal401Children"),
              okButtonLabel: t("client.api.modal401OkButtonLabel"),
              onOk: async ({ close }) => {
                appStore.logout();
              },
              disableBackdropClick: true,
            });
          } else {
            const observable = new Observable((observer) => {
              // used an anonymous function for using an async function
              (async () => {
                try {
                  await refreshToken();

                  // Retry the failed request
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };

                  forward(operation).subscribe(subscriber);
                } catch (err) {
                  observer.error(err);
                }
              })();
            });
            return observable;
          }
          return;
        case 403:
          Modal.alert({
            title: t("client.api.modal403Title"),
            children: t("client.api.modal403Children"),
            okButtonLabel: t("client.api.modal403OkButtonLabel"),
            onOk: async ({ close }) => {
              appStore.logout();
              close();
            },
            disableBackdropClick: true,
          });
          return;
        case 500:
          Modal.alert({
            title: t("client.api.modal500Title"),
            children:
              networkError.result?.errors &&
              networkError.result?.errors[0]?.message
                ? t(networkError.result?.errors[0]?.message)
                : networkError.message,
            okButtonLabel: t("client.api.modal500OkButtonLabel"),
            onOk: async ({ close }) => {
              close();
            },
            disableBackdropClick: true,
          });
          return;
        default:
          return;
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const GEN_REFRESH_TOKEN = gql`
  mutation GEN_REFRESH_TOKEN {
    refreshBackofficeUserRefreshToken {
      accessToken
      refreshToken
    }
  }
`;
client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

const refreshToken = async () => {
  try {
    const refreshResolverResponse = await client.mutate({
      mutation: GEN_REFRESH_TOKEN,
    });
    const {
      accessToken,
      refreshToken,
    } = refreshResolverResponse.data?.refreshBackofficeUserRefreshToken;
    setToken(accessToken);
    setRefreshToken(refreshToken);
    return accessToken;
  } catch (err) {
    localStorage.clear();
    throw err;
  }
};

export const ApiProvider = (props) => (
  <ApolloProvider client={client}>{props.children}</ApolloProvider>
);

export const resetStore = client.resetStore;
export const clearStore = client.clearStore;

export { gql };

export default client;
